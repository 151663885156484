import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { BASE_URL } from "../../global";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { isCovertPossible } from "../../utils/components/unitConvertor";
import { UNCERTAINTY_LINK } from "../master/staticTable/editTable";
import { ClassicTable } from "./../../utils/components/Styles";
import {
  calculateUncertaintyContribution,
  convertUnit,
  generateTableData,
  resolveUncertaintyValue,
  convertUnitWrap,
} from "./utils";
import { typeA_columns, typeB_columns, budget_columns } from "./constants";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";

const UNIT_ROW_VALUE_PREFIX = "_unit_";
const ROW_HEADER_VALUE_PREFIX = "_rh_";
const UNIT_SEPARATOR = "#";

const MEAN_PECISION = 4;
const X_MINUS_X_BAR_PECISION = 4;
const X_MINUS_X_BAR_SQUARE_PECISION = X_MINUS_X_BAR_PECISION + 4;
const SD_PRECISION = X_MINUS_X_BAR_PECISION + 4;
const MSD_PRECISION = X_MINUS_X_BAR_PECISION + 4;

const UNCERTAINTY_PRECISION_COUNT = 4;

const ShowTable = ({ headers, readings }) => {
  return (
    <ClassicTable>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((e, idx) => (
              <TableCell key={`${idx}`}>
                {typeof e.label === "function" ? e.label() : e.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {readings.map((reading, idx) => (
            <TableRow key={`${idx}`}>
              {reading.map((col, id) => {
                let value =
                  typeof col === "string"
                    ? col?.replaceAll(UNIT_SEPARATOR, " ")
                    : typeof col === "function"
                    ? col()
                    : typeof col?.[0] === "function"
                    ? col?.[0]()
                    : col?.[0]?.replaceAll(UNIT_SEPARATOR, " ");

                return (
                  <TableCell
                    colSpan={typeof col === "string" ? 1 : col?.[1] || 1}
                    key={`${idx}-${id}`}
                    style={{
                      color:
                        typeof col === "string"
                          ? col.toString().includes("[wrong formula]")
                            ? "red"
                            : "inherit"
                          : null,
                    }}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ClassicTable>
  );
};

export default function ViewAll() {
  const { datasheetId, instrumentId, type, tableId, readingId } = useParams();

  const [reportDetails, setReportDetails] = useState(null);
  const [allKFactorReferenceData, setAllKFactorReferenceData] = useState([]);
  const [isKvalue, setIsKvalue] = useState(true);
  const [downloadPdfloading, setDownlaodPdfLoading] = useState(false);

  const navigate = useNavigate();

  const printComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
        <td colspan="2" style="border:none;padding:5px;font-family: Calibri, sans-serif;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [30, 1, 20, 1],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 15;
        const borderX = leftMargin;
        const borderY = 30;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 800);

        let image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 0, 595, 40);

        // if (letterHead) {
        //   image = await generateLetterHeadFooter();
        //   pdf.addImage(image, 0, 772, 595, 70);
        // }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `budget_${datasheetId}.pdf`;
      a.click();

      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  const errorFn = (errTxt, err) => {
    toast.error(errTxt + err);
    console.error(errTxt, err);
    return ["err", err];
  };

  const trimPrecision = (value) => {
    // Convert value to string if it's not already a strin
    value = String(value);
    let unit = value.split("#")[1];
    value = value.split("#")[0];

    // Check if the value is in exponential format
    if (value.includes("e") || value.includes("E")) {
      // Parse the number to preserve exponent notation
      let parsedValue = parseFloat(value);
      // Convert back to exponential format with 7 precision
      value = parsedValue.toExponential(7);
    } else {
      // Convert the value to number
      let numValue = Number(value);
      // Check if the number has more than 7 significant digits
      if (numValue.toString().length > 7) {
        // Convert number to string and trim precision
        let trimmedValue = numValue.toFixed(7);
        // Convert back to number and return
        value = Number(trimmedValue);
      }
    }

    return String(value) + (unit ? "#" + unit : "");
  };

  const finalizeUncertaintyWithCMC = (
    uncertainty,
    staticTable,
    datasheetDetails,
    datasheetReading,
    cmcs,
    unitMap,
    baseValues
  ) => {
    let finalUncertainty = null;
    let invalidCmcs = false;
    let lowerCMC = null,
      higherCMC = null;
    let additionalCmcData = "",
      cmcFromRange = null,
      cmcToRange = null;

    if (staticTable) {
      let defaultConfig = staticTable["defaultConfiguration"] || {};
      let budget = defaultConfig["budget"];
      let rangeCol = defaultConfig["rangeCol"];

      // 1. find CMC values
      let cmcValues = cmcs.map((cmc) => ({
        mode: cmc.mode,
        parameter: cmc.parameter,
        paratype: cmc.paratype,
        fromRange: [
          cmc.fromRange[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.fromRange[0]),
                  cmc.fromRange[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          cmc.fromRange[1],
        ],
        toRange: [
          cmc.toRange[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.toRange[0]),
                  cmc.toRange[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          cmc.toRange[1],
        ],
        lowerCmc: [
          cmc.lowerCmc[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.lowerCmc[0]),
                  cmc.lowerCmc[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          cmc.lowerCmc[1],
        ],
        higherCmc: [
          cmc.higherCmc[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.higherCmc[0]),
                  cmc.higherCmc[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          cmc.higherCmc[1],
        ],
      }));

      // 3. find CMC values, convert to respective units and compare with uncertainty, and find final uncertainty
      finalUncertainty = uncertainty;

      if (uncertainty !== undefined && uncertainty !== null && rangeCol) {
        let maxDiff = 0;
        for (let i = 0; i < cmcValues.length; i++) {
          if (
            cmcValues[i]?.fromRange?.[0] === undefined ||
            cmcValues[i]?.toRange?.[0] === undefined
          ) {
            invalidCmcs = true;
            continue;
          }
          if (
            !isCovertPossible(
              cmcValues[i]?.fromRange?.[1],
              unitMap[1] ? unitMap[2] : unitMap[0]
            ) ||
            !isCovertPossible(
              cmcValues[i]?.toRange?.[1],
              unitMap[1] ? unitMap[2] : unitMap[0]
            )
          )
            continue;

          let rangeValue = datasheetReading[rangeCol]?.[0];
          let rangeUnit = datasheetReading[rangeCol]?.[1];
          rangeValue = convertUnit(
            Number(rangeValue),
            rangeUnit,
            cmcValues[i]?.fromRange?.[1]
          );

          if (
            cmcValues[i]?.fromRange?.[0] <= rangeValue &&
            cmcValues[i]?.toRange?.[0] + 0.5 > rangeValue &&
            Math.abs(
              cmcValues[i]?.toRange?.[0] - cmcValues[i]?.fromRange?.[0]
            ) >= maxDiff
          ) {
            maxDiff = Math.abs(
              cmcValues[i]?.toRange?.[0] - cmcValues[i]?.fromRange?.[0]
            );

            if (cmcValues[i]?.mode) {
              additionalCmcData = `\nMode: ${cmcValues[i]?.mode}`;
            }
            if (cmcValues[i]?.parameter) {
              additionalCmcData =
                additionalCmcData + `\nParameter: ${cmcValues[i]?.parameter}`;
            }
            if (cmcValues[i]?.paratype) {
              additionalCmcData =
                additionalCmcData + `\nParatype: ${cmcValues[i]?.paratype}`;
            }

            let lowerCmcValue = cmcValues[i].lowerCmc[0];
            let lowerCmcValueUnit = cmcValues[i].lowerCmc[1];
            let higherCmcValue = cmcValues[i].higherCmc[0];
            let higherCmcValueUnit = cmcValues[i].higherCmc[1];

            let desiredCombinedUncertaintyUnit = unitMap[1]
              ? unitMap[2]
              : unitMap[0];

            cmcFromRange = `${cmcValues[i]?.fromRange[0]}#${desiredCombinedUncertaintyUnit}`;
            cmcToRange = `${cmcValues[i]?.toRange[0]}#${desiredCombinedUncertaintyUnit}`;

            // 1. convert higher and lower cmc values to desired unit
            if (lowerCmcValueUnit?.includes("%")) {
              let baseValue = lowerCmcValueUnit.toLowerCase().includes("fsd")
                ? baseValues?.percentFSDBaseValue
                : baseValues?.percentBaseValue;

              lowerCMC =
                (lowerCmcValue || "") + "#" + (lowerCmcValueUnit || "");

              lowerCmcValue = (lowerCmcValue * baseValue) / 100;

              lowerCMC =
                lowerCMC +
                ` (${lowerCmcValue}#${desiredCombinedUncertaintyUnit})`;
            } else {
              lowerCmcValue = convertUnitWrap(
                lowerCmcValue,
                lowerCmcValueUnit,
                desiredCombinedUncertaintyUnit
              );
              lowerCMC =
                (lowerCmcValue || "") + "#" + (lowerCmcValueUnit || "");
            }

            if (higherCmcValueUnit?.includes("%")) {
              let baseValue = lowerCmcValueUnit.toLowerCase().includes("fsd")
                ? baseValues?.percentFSDBaseValue
                : baseValues?.percentBaseValue;
              higherCMC =
                (higherCmcValue || "") + "#" + (higherCmcValueUnit || "");
              higherCmcValue = (higherCmcValue * baseValue) / 100;
              higherCMC =
                higherCMC +
                ` (${higherCmcValue}#${desiredCombinedUncertaintyUnit})`;
            } else {
              higherCmcValue = convertUnitWrap(
                higherCmcValue,
                higherCmcValueUnit,
                desiredCombinedUncertaintyUnit
              );
              higherCMC =
                (higherCmcValue || "") + "#" + (higherCmcValueUnit || "");
            }

            if (higherCmcValue) {
              if (higherCmcValue > uncertainty) {
                finalUncertainty = higherCmcValue;
              } else if (lowerCmcValue && lowerCmcValue > uncertainty) {
                finalUncertainty = lowerCmcValue;
              }
            } else {
              if (lowerCmcValue > uncertainty) {
                finalUncertainty = lowerCmcValue;
              }
            }
          }
        }
        if (invalidCmcs) {
          toast.warning("Please Correct the CMC values");
        }
      } else {
        toast.warning(
          "Please set Range Column using Template, to compare cmc!"
        );
      }

      // 4. calculate uncertainty percentage if applicable (if unit of uncertainty is in %)
      if (unitMap[0]?.toLowerCase()?.includes("%fsd")) {
        let rangeValue = datasheetDetails?.ranges?.split("|")?.[1];
        rangeValue = rangeValue?.split("#")?.[0];
        let baseValue = Number(rangeValue);

        uncertainty = convertUnit(
          Number(uncertainty),
          unitMap[2]?.includes("%") ? "%" : unitMap[2],
          unitMap[0]?.includes("%") ? "%" : unitMap[0],
          baseValue
        );

        finalUncertainty = convertUnit(
          Number(finalUncertainty),
          unitMap[2]?.includes("%") ? "%" : unitMap[2],
          unitMap[0]?.includes("%") ? "%" : unitMap[0],
          baseValue
        );
      } else if (unitMap[0]?.includes("%")) {
        let baseValue = datasheetReading[rangeCol]?.[0];
        uncertainty = convertUnit(
          Number(uncertainty),
          unitMap[2],
          unitMap[0],
          baseValue
        );

        finalUncertainty = convertUnit(
          Number(finalUncertainty),
          unitMap[2],
          unitMap[0],
          baseValue
        );
      }
    }

    return [
      trimPrecision(finalUncertainty),
      uncertainty,
      lowerCMC,
      higherCMC,
      cmcFromRange,
      cmcToRange,
      additionalCmcData,
    ];
  };

  const calcUncertainty = (
    uncertaintyFactorRows,
    cmcs,
    staticTable,
    datasheetDetails,
    datasheetReading,
    unitMap,
    baseValues
  ) => {
    // 1. calculate sum of uncertainty contribution
    let sumOfUncertaintyContribution = 0;
    uncertaintyFactorRows?.forEach((element) => {
      let uncertaintyContribution = element["uncertaintyContribution"];
      let [uncertaintyContributionValue, uncertaintyContributionUnit] =
        uncertaintyContribution?.split("#");
      uncertaintyContributionValue = Number(uncertaintyContributionValue);
      sumOfUncertaintyContribution += Math.pow(uncertaintyContributionValue, 2);
    });

    // 2. calculate combined uncertainty
    let combinedUncertainty = Math.sqrt(sumOfUncertaintyContribution);

    // 2.1 calculate effective degrees of freedom
    /*
        Steps  : calculate effective degree  of freedom 
        Formula :  Combine uncertainty(UC)^4 /( Uub1^4/Degree of freedom +Uub2^4/Degree of freedom + …)
    */
    let effectiveDegreesOfFreedom = 0;
    uncertaintyFactorRows.forEach((element) => {
      if (element["degreesOfFreedom"] !== "∞") {
        let uncertaintyContribution = element["uncertaintyContribution"];
        let [uncertaintyContributionValue, uncertaintyContributionUnit] =
          uncertaintyContribution?.split("#");
        uncertaintyContributionValue = Number(uncertaintyContributionValue);
        let degreeOfFreedom = Number(element["degreesOfFreedom"]);
        effectiveDegreesOfFreedom +=
          Math.pow(uncertaintyContributionValue, 4) / degreeOfFreedom;
      }
    });

    if (effectiveDegreesOfFreedom > 0) {
      effectiveDegreesOfFreedom =
        Math.pow(combinedUncertainty, 4) / effectiveDegreesOfFreedom;
    } else {
      effectiveDegreesOfFreedom = "Infinity";
    }
    // 2.2 calculate K factor value
    let KDegreeOfFreedom =
      effectiveDegreesOfFreedom > 30
        ? "Infinity"
        : Math.ceil(effectiveDegreesOfFreedom);
    let K = allKFactorReferenceData?.find(
      (ele) => ele.degreeOfFreedom >= KDegreeOfFreedom
    )?.value;

    // 3. calculate expanded uncertainty
    let expandedUncertainty = Math.abs(combinedUncertainty * K);

    let [
      finalExpandedUncertainty,
      expandedUncertaintyInDesiredUnit,
      lowerCMC,
      higherCMC,
      cmcFromRange,
      cmcToRange,
      additionalCmcData,
    ] = finalizeUncertaintyWithCMC(
      Number(expandedUncertainty),
      staticTable,
      datasheetDetails,
      datasheetReading,
      cmcs,
      unitMap,
      baseValues
    );

    // 3.1 make uncertainty absolute
    finalExpandedUncertainty = Math.abs(Number(finalExpandedUncertainty));

    // 4. append respective units to values
    let desiredCombinedUncertaintyUnit = unitMap[1] ? unitMap[2] : unitMap[0];
    let desiredUncertaintyUnit = unitMap[0];

    combinedUncertainty =
      combinedUncertainty + "#" + desiredCombinedUncertaintyUnit;
    expandedUncertainty =
      expandedUncertainty + "#" + desiredCombinedUncertaintyUnit;

    if (desiredUncertaintyUnit.includes("%")) {
      expandedUncertainty =
        expandedUncertaintyInDesiredUnit +
        "#" +
        desiredUncertaintyUnit +
        ` (${expandedUncertainty})`;
    }
    if (String(finalExpandedUncertainty).toLowerCase() === "infinity") {
      finalExpandedUncertainty = "--";
    } else {
      finalExpandedUncertainty =
        finalExpandedUncertainty + "#" + desiredUncertaintyUnit;
    }

    return [
      combinedUncertainty,
      effectiveDegreesOfFreedom,
      K,
      expandedUncertainty,
      finalExpandedUncertainty,
      lowerCMC,
      higherCMC,
      cmcFromRange,
      cmcToRange,
      additionalCmcData,
    ];
  };

  const resolveUncertaintyRows = (
    standardRanges,
    uncertaintyFactors,
    uncertaintiesMap,
    id,
    fallbackUnit,
    supportiveRanges,
    readings,
    rels,
    rangeCol
  ) => {
    let rows = [];

    // 1. process by standard instruments
    for (let i = 0; i < standardRanges.length; i++) {
      for (let j = 0; j < uncertaintyFactors.length; j++) {
        if (
          uncertaintiesMap[standardRanges[i][0]]?.includes(
            `${uncertaintyFactors[j].id}`
          )
        ) {
          // 1. resolve source and standard coefficient formulas
          let selectors = {
            standard: standardRanges[i][0],
            standardRange: standardRanges[i][1],
            datasheet: datasheetId,
            srfInstrument: datasheetId,
            instrument: instrumentId,
            datasheeetStaticTable: tableId,
            datasheetStaticReading: id,
          };
          let referenceData = {
            readingRow: readings,
          };
          let value = resolveUncertaintyValue(
            uncertaintyFactors[j],
            selectors,
            referenceData,
            fallbackUnit
          );

          // 2. if factor is linked to reading column the take value+unit from reading row
          if (rels[uncertaintyFactors[j].id]) {
            let _value = readings[rels[uncertaintyFactors[j].id]][0];
            _value = String(_value).replace(/[$]+/, "");
            value[0] = `${
              _value + "#" + readings[rels[uncertaintyFactors[j].id]][1]
            }`;
          }
          rows.push([uncertaintyFactors[j], ...value]);
        }
      }
    }

    // 2. process by supportive instruments
    if (supportiveRanges) {
      for (let j = 0; j < uncertaintyFactors.length; j++) {
        if (
          uncertaintiesMap[supportiveRanges?.standardId]?.includes(
            `${uncertaintyFactors[j].id}`
          )
        ) {
          let selectors = {
            standard: supportiveRanges?.standardId,
            standardRange: supportiveRanges?.id,
            datasheet: datasheetId,
            srfInstrument: datasheetId,
            instrument: instrumentId,
            datasheeetStaticTable: tableId,
            datasheetStaticReading: id,
          };
          let referenceData = {
            readingRow: readings,
          };
          let value = resolveUncertaintyValue(
            uncertaintyFactors[j],
            selectors,
            referenceData,
            fallbackUnit
          );

          // 2. if factor is linked to reading column the take value+unit from reading row
          if (rels[uncertaintyFactors[j].id]) {
            let _value = readings[rels[uncertaintyFactors[j].id]][0];
            _value = String(_value).replace(/[$]+/, "");
            value[0] = `${
              _value + "#" + readings[rels[uncertaintyFactors[j].id]][1]
            }`;
          }
          rows.push([uncertaintyFactors[j], ...value]);
        }
      }
    }

    // 3. process common uncertainty factors
    for (let j = 0; j < uncertaintyFactors.length; j++) {
      if (uncertaintiesMap[0]?.includes(`${uncertaintyFactors[j].id}`)) {
        let selectors = { instrument: instrumentId, datasheet: datasheetId };
        let referenceData = {
          readingRow: readings,
        };
        let value = resolveUncertaintyValue(
          uncertaintyFactors[j],
          selectors,
          referenceData,
          fallbackUnit
        );

        // 2. if factor is linked to reading column the take value+unit from reading row
        if (rels[uncertaintyFactors[j].id]) {
          let _value = readings[rels[uncertaintyFactors[j].id]][0];
          _value = String(_value).replace(/[$]+/, "");
          value[0] = `${
            _value + "#" + readings[rels[uncertaintyFactors[j].id]][1]
          }`;
        }
        rows.push([uncertaintyFactors[j], ...value]);
      }
    }

    return rows;
  };

  const processTypeARows = ({ inputs, unit }) => {
    if (!inputs) return {};
    inputs = inputs.filter((e) => e !== null && e !== undefined);

    if (!inputs || inputs?.length <= 0) return {};

    let mean = inputs.reduce((s, v) => s + v, 0) / inputs.length;
    mean = mean.toFixed(MEAN_PECISION);
    let rows = [];

    let x_xbar_sqr_sum = 0;
    inputs.map((e, i) => {
      rows.push([
        `${i + 1}`,
        `${e} ${unit}`,
        `${mean} ${unit}`,
        (e - mean).toFixed(X_MINUS_X_BAR_PECISION),
        Math.pow(e - mean, 2).toFixed(X_MINUS_X_BAR_SQUARE_PECISION),
      ]);
      x_xbar_sqr_sum += Number(rows[rows.length - 1][4]);
    });
    x_xbar_sqr_sum = x_xbar_sqr_sum.toFixed(X_MINUS_X_BAR_SQUARE_PECISION);

    let sd = Math.sqrt(x_xbar_sqr_sum / (inputs.length - 1));
    sd = sd.toFixed(SD_PRECISION);
    let meanOfStdDev = sd / Math.sqrt(rows.length);
    meanOfStdDev = meanOfStdDev.toFixed(MSD_PRECISION);
    rows.push([
      ["", 3],
      () => (
        <>
          (x-x̄)<sup>2</sup>
        </>
      ),
      x_xbar_sqr_sum,
    ]);

    sd = sd + "#" + unit;
    meanOfStdDev = meanOfStdDev + "#" + unit;

    rows.push([
      ["STANDARD DEVIATION OF THE MEAN (σ)", 4],
      `${sd.replace("#", " ")}`,
    ]);
    rows.push([
      ["MEAN OF STANDARD DEVIATION", 4],
      `${meanOfStdDev.replace("#", " ")}`,
    ]);
    rows.push([["DEGREES OF FREEDOM", 4], `${inputs.length - 1}`]);
    return { rows, mean, sd, meanOfStdDev, n: inputs.length };
  };

  const processTypeBRows = ({
    id,
    inputs,
    fallbackUnit,
    supportiveRanges,
    uncertaintiesMap,
    staticTable,
    readings,
  }) => {
    if (!inputs) return {};

    let reverseMap = {};
    let standards = readings.standardRanges.map((e) => e);
    let typebRels = { ...staticTable.defaultConfiguration.typeb.relations };
    let rangeCol = (staticTable["defaultConfiguration"] || {})?.["rangeCol"];

    Object.keys(typebRels).map((e) => (reverseMap[typebRels[e]] = e));

    let uncertaintyFactorRows = resolveUncertaintyRows(
      standards,
      inputs,
      uncertaintiesMap,
      id,
      fallbackUnit,
      supportiveRanges,
      readings,
      reverseMap,
      rangeCol
    );
    return {
      rows: uncertaintyFactorRows.map((uf, i) => {
        let uncertaintyFactor = uf[0];
        let uncertaintyFactorValue = trimPrecision(uf[1]);
        let sensitiveCoefficient = uf[2];

        return [
          `UB${i + 1}`,
          () => (
            <>
              {uncertaintyFactor.name}
              <br />
              {`${uncertaintyFactorValue?.replaceAll("#", " ")}`}
            </>
          ),
          uncertaintyFactor.distribution,
          "ω",
          `Sensitivity Coeff. = ${sensitiveCoefficient}`,
          `${uncertaintyFactorValue}`,
        ];
      }),
    };
  };

  const processBudgetRows = ({
    id,
    inputs,
    unit,
    supportiveRanges,
    uncertaintiesMap,
    typeA,
    cmcs,
    staticTable,
    datasheetDetails,
    readings,
    standardRanges,
  }) => {
    if (!inputs) return {};

    let reverseMap = {};

    let standardRangeIds = readings.standardRanges.map((e) => e);
    let typebRels = { ...staticTable.defaultConfiguration.typeb.relations };

    // 1. prepare base values for % unit conversions
    let baseValues = { percentBaseValue: 0, percentFSDBaseValue: 0 };
    let rangeCol = (staticTable["defaultConfiguration"] || {})?.["rangeCol"];

    if (rangeCol) {
      baseValues["percentBaseValue"] = readings[rangeCol][0];

      let rangeValue = datasheetDetails?.ranges?.split("|")?.[1];
      rangeValue = rangeValue?.split("#")?.[0];
      let baseValue = Number(rangeValue);
      baseValues["percentFSDBaseValue"] = baseValue;
    }

    Object.keys(typebRels).map((e) => (reverseMap[typebRels[e]] = e));
    let uncertaintyFactorRows = resolveUncertaintyRows(
      standardRangeIds,
      inputs,
      uncertaintiesMap,
      id,
      unit[1] ? unit[2] : unit[0],
      supportiveRanges,
      readings,
      reverseMap,
      rangeCol
    );

    let rows = uncertaintyFactorRows.map((uf, i) => {
      let uncertaintyFactorValue = uf[1];
      let [ufValue, ufUit] = uncertaintyFactorValue?.split("#") || [null, null];
      ufValue = Number(ufValue);

      let sensitiveCoefficient = uf[2];
      let valueAdjustment = uf[3] || "0";

      // apply limits to uncertanity factor value
      let limitPercent = Number(uf[0]?.limits || 100);
      let ufLimitXi = ufValue * (limitPercent / 100);

      let uncertaintyContribution = calculateUncertaintyContribution(
        ufLimitXi + `#${ufUit}`,
        Number(uf[0].formula),
        sensitiveCoefficient,
        valueAdjustment,
        unit[1] ? unit[2] : unit[0],
        baseValues
      );

      return {
        factorLabel: () => (
          <>
            UB{i + 1}
            <br />
            {uf[0].name}
          </>
        ),
        estimate: uncertaintyFactorValue,
        limitXi: ufLimitXi + "#" + ufUit,
        probabilityDistribution: uf[0].distribution,
        standardUncertainty:
          `${Number(ufLimitXi / uf[0].formula)}` + "#" + ufUit,
        sensitiveCoefficient: `${sensitiveCoefficient}`,
        uncertaintyContribution: `${uncertaintyContribution}`,
        degreesOfFreedom: uf[0]?.degreesOfFreedom || "∞",
      };
    });

    if (typeA.meanOfStdDev) {
      let sd = Number(typeA.sd.split("#")[0]);
      let sdUnit = typeA.sd.split("#")[1];

      let uc = calculateUncertaintyContribution(
        `${sd}#${sdUnit}`,
        Math.sqrt(Number(typeA.n)),
        "1",
        "0",
        unit[1] ? unit[2] : unit[0],
        baseValues
      );

      rows = rows.concat({
        factorLabel: "Repeatability",
        estimate: `${sd}#${sdUnit}`,
        limitXi: `${sd}#${sdUnit}`,
        probabilityDistribution: `√${typeA.n}`,
        standardUncertainty: `${
          Number(sd) / Math.sqrt(Number(typeA.n))
        }#${sdUnit}`,
        sensitiveCoefficient: `1`,
        uncertaintyContribution: `${uc}`,
        degreesOfFreedom: `${typeA.n - 1}`,
      });
    }

    let [
      combinedUncertainty,
      effectiveDegreesOfFreedom,
      K,
      uncertainty,
      finalExpandedUncertainty,
      lowerCMC,
      higherCMC,
      cmcFromRange,
      cmcToRange,
      additionalCmcData,
    ] = calcUncertainty(
      rows,
      cmcs,
      staticTable,
      datasheetDetails,
      readings,
      unit,
      baseValues
    );

    let rowsToRender = [];

    rows.forEach((row, i) => {
      rowsToRender.push([
        row["factorLabel"],
        trimPrecision(row["estimate"]),
        trimPrecision(row["limitXi"]),
        row["probabilityDistribution"],
        trimPrecision(row["standardUncertainty"]),
        row["sensitiveCoefficient"],
        trimPrecision(row["uncertaintyContribution"]),
        row["degreesOfFreedom"],
      ]);
    });

    rowsToRender = rowsToRender.concat([
      [
        "",
        `Uc`,
        ``,
        ``,
        `${trimPrecision(combinedUncertainty)}`,
        `1`,
        `${trimPrecision(combinedUncertainty)}`,
        `∞`,
      ],
      [
        "",
        `Expanded Uncertainty`,
        `K=`,
        `${K}`,
        ``,
        ``,
        `${finalExpandedUncertainty}`,
        `∞`,
      ],
    ]);

    return {
      rows: rowsToRender,
      extra: {
        uc: combinedUncertainty,
        freedom: effectiveDegreesOfFreedom,
        uncertainty,
        finalExpandedUncertainty,
        lowerCMC,
        higherCMC,
        cmcFromRange,
        cmcToRange,
        additionalCmcData,
      },
    };
  };

  const buildTypeA = (rows, second) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip
            label={`TYPE-A ${second ? 2 : 1} EVALUATION`}
            style={{ fontWeight: "bold" }}
          />
        </Divider>
        <ShowTable headers={typeA_columns} readings={rows} />
        <Grid
          container
          spacing={2}
          sx={{ mt: 2 }}
          style={{ marginTop: "80px" }}
        >
          <Grid item xs={4}>
            <Typography align="center">Calibrated By</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography align="center">Approved By</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const buildTypeB = (rows) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip
            label="Type-B Contribution
          "
            style={{ fontWeight: "bold" }}
          />
        </Divider>
        <ShowTable headers={typeB_columns} readings={rows} />
      </Paper>
    );
  };

  const buildBudget = (rows, params) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip label="Uncertainty Budget" style={{ fontWeight: "bold" }} />
        </Divider>

        <ShowTable headers={budget_columns} readings={rows} />

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="COMBINED UNCERTAINTY (Uc)"
              value={params?.uc?.replaceAll("#", " ")}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="EFFECTIVE DEGREES OF FREEDOM"
              value={params?.freedom}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Expanded Uncertainty
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={"text"}
                value={params?.finalExpandedUncertainty?.replaceAll("#", " ")}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        <div style={{ fontSize: "12px" }}>
                          calculated uncertainty:{" "}
                          {params?.uncertainty?.replaceAll("#", " ")}
                          <br />
                          {params?.additionalCmcData}
                          <br />
                          CMC Range:{" "}
                          {params?.cmcFromRange?.replaceAll("#", " ")} to{" "}
                          {params?.cmcToRange?.replaceAll("#", " ")}
                          <br />
                          lower CMC:{" "}
                          {params?.lowerCMC?.replaceAll("#", " ") || "NA"}
                          <br />
                          higher CMC:{" "}
                          {params?.higherCMC?.replaceAll("#", " ") || "NA"}
                        </div>
                      }
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
                label="Expanded Uncertainty "
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const getTypeAColumns = (table, second) => {
    return Object.entries(table)
      .map((map) => {
        if (
          (second && String(map[1]).match(/d+\d+/g)) ||
          (!second && String(map[1]).match(/m+\d+/g))
        ) {
          return map[0];
        }
      })
      .filter((val) => {
        if (val) return val;
      });
  };

  const parseCMCs = (cmcs) => {
    let newCmcs = [];
    cmcs.map((cmc) =>
      newCmcs.push({
        fromRange: (cmc.fromRange || "").split(UNIT_SEPARATOR),
        toRange: (cmc.toRange || "").split(UNIT_SEPARATOR),
        lowerCmc: (cmc.lowerCmc || "").split(UNIT_SEPARATOR),
        higherCmc: (cmc.higherCmc || "").split(UNIT_SEPARATOR),
        id: cmc.id,
        instrumentId: cmc.instrumentId,
        mode: cmc.mode,
        parameter: cmc.parameter,
        paratype: cmc.paratype,
      })
    );
    return newCmcs;
  };

  const processReportRows = async (
    table,
    datasheetDetails,
    readings,
    uncertaintiesMap,
    cmcs
  ) => {
    // fun: process and preapre data for typeA, typeB and budget

    let tableA1 = [];
    let tableA2 = [];
    let staticTable = parseTable(table);
    let parsedReadings = parseReadings(readings);

    let standardRanges = await fetchStandardRanges(
      readings
        .map((e) => e.standardRanges?.split(":")?.[1]?.split(",")?.[0])
        .flat(Infinity)
        .filter((e) => e)
    );
    let supportiveRanges = (
      await fetchSupprtiveRanges(
        parsedReadings.map((reading) => reading.supportiveRanges)
      )
    )[0]?.[1];
    supportiveRanges = supportiveRanges || [];
    let uncertaintyFactors = (
      await fetchUncertaintyFactors(parsedReadings, uncertaintiesMap)
    )[0][1];
    cmcs = parseCMCs(cmcs);
    if (staticTable) {
      tableA1 = getTypeAColumns(staticTable);
      tableA2 = getTypeAColumns(staticTable, true);
    }

    let reportRows = [];
    if (parsedReadings.length > 0) {
      await generateTableData(
        uncertaintyFactors,
        parsedReadings,
        tableId,
        datasheetId,
        instrumentId,
        uncertaintiesMap
      );

      reportRows = await Promise.all(
        parsedReadings.map(async (readingRow, i) => {
          let uncertaintyUnit =
            readingRow.unitMap?.uncertainty?.split(UNIT_ROW_VALUE_PREFIX)[1] ||
            "";

          let rangeCol =
            (staticTable["defaultConfiguration"] || {})?.["rangeCol"] || "";
          let rangeColUnit =
            readingRow.unitMap?.[rangeCol]?.split(UNIT_ROW_VALUE_PREFIX)[1] ||
            "";

          let typeA1 = processTypeARows({
            inputs: tableA1
              .map((a) => readingRow[a][0])
              ?.filter((e) => !isNaN(e)),
            unit:
              tableA1.length > 0
                ? readingRow.unitMap[tableA1[0]]?.split(
                    UNIT_ROW_VALUE_PREFIX
                  )?.[1]
                : "",
          });

          let typeA2 = processTypeARows({
            inputs: tableA2
              .map((a) => readingRow[a][0])
              ?.filter((e) => !isNaN(e)),
            unit:
              tableA2.length > 0
                ? readingRow.unitMap[tableA2[0]]?.split(
                    UNIT_ROW_VALUE_PREFIX
                  )?.[1]
                : "",
          });

          let typeB = readingRow.standardRanges?.length
            ? processTypeBRows({
                id: `${readingRow.id}`,
                inputs: uncertaintyFactors,
                fallbackUnit: rangeColUnit,
                supportiveRanges: readingRow.supportiveRanges[1]
                  ? supportiveRanges[readingRow.supportiveRanges[1]]
                  : undefined,
                uncertaintiesMap: uncertaintiesMap,

                staticTable,
                readings: readingRow,
              })
            : {};

          let budget = readingRow.standardRanges?.length
            ? processBudgetRows({
                id: `${readingRow.id}`,
                inputs: uncertaintyFactors,
                unit: [
                  readingRow.unitMap?.uncertainty?.split(
                    UNIT_ROW_VALUE_PREFIX
                  )[1] || "",
                  uncertaintyUnit?.includes("%") ? true : false,
                  tableA1.length > 0
                    ? readingRow.unitMap[tableA1[0]]?.split(
                        UNIT_ROW_VALUE_PREFIX
                      )?.[1]
                    : rangeColUnit,
                ],
                supportiveRanges: readingRow.supportiveRanges[1]
                  ? supportiveRanges[readingRow.supportiveRanges[1]]
                  : undefined,
                uncertaintiesMap: uncertaintiesMap,
                typeA: typeA2?.meanOfStdDev
                  ? typeA1.meanOfStdDev > typeA2.meanOfStdDev
                    ? typeA1
                    : typeA2
                  : typeA1,
                cmcs,
                staticTable,
                datasheetDetails,
                readings: readingRow,
                standardRanges: [
                  standardRanges[Number(readingRow.standardRanges[0][1])],
                ],
              })
            : {};

          return [
            [
              tableA1?.length > 0 ? typeA1.rows : [],
              tableA2?.length > 0 ? typeA2.rows : [],
            ],
            typeB.rows,
            [budget.rows, budget.extra],
            readingRow.id,
          ];
        })
      );
    }
    return reportRows;
  };

  const parseTable = (table) => {
    let parsedTable = { ...table };
    parsedTable.conditionFormatting = JSON.parse(table.conditionFormatting);
    parsedTable.defaultConfiguration = JSON.parse(table.defaultConfiguration);
    return parsedTable;
  };

  const parseReadings = (readings) => {
    let cu = {};
    let parsedReadings = [];
    let index = 0;
    readings.map((e) => {
      if (e["c1"]?.includes(UNIT_ROW_VALUE_PREFIX)) cu[e.tableId] = e;
      if (
        e["c1"]?.includes(UNIT_ROW_VALUE_PREFIX) ||
        e["c1"]?.includes(ROW_HEADER_VALUE_PREFIX)
      )
        return null;

      parsedReadings.push({});

      parsedReadings[index].unitMap = cu[e.tableId];

      Object.keys(e).map((e2, i) => {
        if ((e2.match(/c+\d+/g) || e2 === "uncertainty") && e[e2]) {
          let [value, unit] = e[e2].split("#");
          value = value.replace(/[$]+/, "");
          value = isNaN(value) || value === "" ? null : Number(value);
          parsedReadings[index][e2] = [value, unit];
        } else if (e2 === "standardRanges") {
          parsedReadings[index]["standardRanges"] = e[e2]
            ? e[e2].split(",").map((e3) => e3.split(":"))
            : [];
        } else if (e2 === "supportiveRanges") {
          parsedReadings[index]["supportiveRanges"] = e[e2]
            ? e[e2].split(":")
            : [];
        } else parsedReadings[index][e2] = e[e2];
      });
      index += 1;
    });
    return parsedReadings;
  };

  const fetchTables = () => {
    return axiosWithToken
      .get(BASE_URL + `datasheetStaticTables?_where=(id,eq,${tableId})`)
      .then((res) => [["table", res.data[0]]])
      .catch((err) =>
        errorFn("datasheetStaticTables data fetching error : ", err)
      );
  };

  const fetchReadings = () => {
    return axiosWithToken
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})~and(tableId,eq,${tableId})${
            type === 1 ? `(is,eq,${readingId})` : ""
          }`
      )
      .then((res) => [["reading", res.data]])
      .catch((err) =>
        errorFn("datasheetStaticReadings data fetching error : ", err)
      );
  };

  const fetchDatasheetDetails = () => {
    return axiosWithToken
      .get(BASE_URL + `datasheets?_where=(id,eq,${datasheetId})`)
      .then((res) => [["datasheetDetails", res.data[0]]])
      .catch((err) => errorFn("datasheet data fetching error : ", err));
  };

  const fetchUncertaintyFactors = async (readings, uncertaintiesMap) => {
    let uf = Object.entries(uncertaintiesMap)
      .map((e) => e[1])
      .flat()
      .join(",");

    return axiosWithToken
      .get(BASE_URL + `uncertainty?_where=(id,in,${uf})`)
      .then(async (res) => [["uncertainty", res.data]])
      .catch((err) =>
        errorFn("uncertainty factors data fetching error: ", err)
      );
  };

  const fetchUncertaintiesMap = () => {
    return axiosWithToken
      .get(
        BASE_URL +
          `instruments?_fields=uncertaintyFactors,commonUncertaintyFactors&_where=(id,eq,${instrumentId})`
      )
      .then((res) => {
        let uMap = {};
        (res.data[0]?.uncertaintyFactors || "")
          .split("|")
          .map((e) =>
            e.split(":").map((e2, i) => (i === 0 ? e2 : (e2 || "").split(",")))
          )
          .forEach((e) => (uMap[e[0]] = e[1]));

        let commonUncertaintyFactors =
          res.data[0]?.commonUncertaintyFactors || "";
        commonUncertaintyFactors = commonUncertaintyFactors
          ? "0:" + commonUncertaintyFactors
          : null;
        if (commonUncertaintyFactors) {
          (commonUncertaintyFactors || "")
            .split("|")
            .map((e) =>
              e
                .split(":")
                .map((e2, i) => (i === 0 ? e2 : (e2 || "").split(",")))
            )
            .forEach((e) => (uMap[e[0]] = e[1]));
        }
        return [["uncertaintiesMap", uMap]];
      })
      .catch((err) => errorFn("instrument data fetching error : ", err));
  };

  const fetchSupprtiveRanges = (supportives) => {
    let ids = supportives?.map((s) => s[1]).filter((e) => e);
    if (!ids || ids.length <= 0) return [];
    return axiosWithToken
      .get(
        BASE_URL +
          `xjoin?_join=s.standards,_j,sr.standardRanges&_on1=(s.id,eq,sr.standardId)&_where=(sr.id,in,${ids})&_fields=sr.id,sr.standardId,sr.axialUniformity,sr.radialUniformity,sr.stability,s.standardName,s.stId`
      )
      .then((res) => {
        let srMap = {};
        res.data.map(
          (e) =>
            (srMap[e.sr_id] = {
              id: e.sr_id,
              standardId: e.sr_standardId,
              axialUniformity: e.sr_axialUniformity,
              radialUniformity: e.sr_radialUniformity,
              stability: e.sr_stability,
              standardName: e.s_standardName,
              stId: e.s_stId,
            })
        );
        return [["supportiveRanges", srMap]];
      })
      .catch((err) => [errorFn("instrument data fetching error : ", err)]);
  };

  const fetchStandardRanges = async (standardRangeIds) => {
    if (!standardRangeIds || standardRangeIds.length <= 0) return [];
    standardRangeIds = standardRangeIds.join(",");
    let res = await axiosWithToken.get(
      BASE_URL +
        `standardRanges?_where=(id,in,${standardRangeIds})&_fields=id,rangeName`
    );
    let srMap = {};
    res.data.forEach((e) => {
      srMap[e.id] = {
        id: e.id,
        fromRange: e.rangeName?.split("|")?.[0],
        toRange: e.rangeName?.split("|")?.[1],
      };
    });
    return srMap;
  };

  const fetchCmcs = async () => {
    let query = `SELECT * FROM cmc where instrumentId=${instrumentId} and accreditationType = (select calibrationType from srfInstruments where id=${datasheetId});`;
    let res = await axiosWithToken.post(BASE_URL + `dynamic`, { query });
    return [["cmcs", res.data]];
  };

  const fetchData = async () => {
    let data = {};
    let arrayData = [
      ...(await fetchTables()),
      ...(await fetchDatasheetDetails()),
      ...(await fetchReadings()),
      ...(await fetchUncertaintiesMap()),
      ...(await fetchCmcs()),
    ];
    arrayData.map((e) => (data[e[0]] = e[1]));
    if (!data.reading || data.reading?.length == 0) {
      window.confirm("Please add units and reading rows first!");
      navigate(`/datasheet/edit/${datasheetId}/${instrumentId}`);
      window.location.reload(false);
    }
    data.parsedReadings = parseReadings(data.reading);

    for (let e of data.parsedReadings) {
      if (!e.unitMap) {
        window.confirm("Please add unit row for each reading row!");
        navigate(`/datasheet/edit/${datasheetId}/${instrumentId}`);
        window.location.reload(false);
      }
    }

    arrayData.map((e) => (data[e[0]] = e[1]));

    let preparedReportRows = await processReportRows(
      data.table,
      data.datasheetDetails,
      data.reading,
      data.uncertaintiesMap,
      data.cmcs
    );

    await Promise.all([
      ...preparedReportRows.map((uncertainty) => {
        let input = {};
        if (
          uncertainty?.[2]?.[1]?.finalExpandedUncertainty !== undefined &&
          uncertainty?.[2]?.[1]?.finalExpandedUncertainty !== null
        )
          input.uncertainty = uncertainty?.[2]?.[1]?.finalExpandedUncertainty;
        Object.entries(data.table).map((col) => {
          if (
            `${col[1]}`.includes(UNCERTAINTY_LINK) &&
            uncertainty[0] &&
            (uncertainty[0][0] || uncertainty[0][1]) &&
            (uncertainty[0][0].length > 0 || uncertainty[0][1].length > 0) &&
            (uncertainty[0][0]?.[uncertainty[0][0].length - 2]?.[1]?.replaceAll(
              " ",
              "#"
            ) ||
              uncertainty[0][1]?.[
                uncertainty[0][1].length - 2
              ]?.[1]?.replaceAll(" ", "#"))
          ) {
            if (
              `${col[1]}`.split(UNCERTAINTY_LINK)[1].toLowerCase() === "typea1"
            )
              input[`${col[0]}`] = uncertainty[0][0]?.[
                uncertainty[0][0].length - 2
              ]?.[1]?.replaceAll(" ", "#");
            else if (
              `${col[1]}`.split(UNCERTAINTY_LINK)[1].toLowerCase() === "typea2"
            )
              input[`${col[0]}`] = uncertainty[0][1]?.[
                uncertainty[0][1].length - 2
              ]?.[1]?.replaceAll(" ", "#");
          }
        });
        return Object.keys(input).length > 0
          ? axiosWithToken.patch(
              BASE_URL +
                `datasheetStaticReadings/${
                  uncertainty[uncertainty.length - 1]
                }`,
              input
            )
          : [];
      }),
    ]);

    setReportDetails([...preparedReportRows]);
  };

  const fetchKFactorReferenceData = () => {
    try {
      const sqlQuery = {
        query: `SELECT kr.* FROM KFactorReference kr JOIN settings s ON kr.fractionPercent = s.value WHERE s.keyName = 'KFactorFactionPercent' AND s.status = 1`,
      };

      axiosWithToken
        .post(BASE_URL + `dynamic`, sqlQuery)
        .then((res) => {
          setAllKFactorReferenceData(res.data);
          // Check if data is empty and set isKvalue accordingly
          setIsKvalue(res.data.length > 0);
        })
        .catch((error) => {
          console.log(error);
          // Set isKvalue to false on error
          setIsKvalue(false);
        });
    } catch (error) {
      console.log("KFactorReference data fetching error: ", error);
      // Set isKvalue to false on error
      setIsKvalue(false);
    }
  };

  useEffect(() => {
    fetchKFactorReferenceData();
  }, []);

  useEffect(() => {
    if (allKFactorReferenceData?.length > 0) fetchData();
  }, [allKFactorReferenceData]);

  if (!reportDetails || allKFactorReferenceData?.length == 0) {
    return (
      <>
        {!isKvalue ? (
          <Paper
            sx={{
              textTransform: "capitalize",
              height: "40%",
              width: "70%",
              margin: "30px auto",
              p: 2,
              display: "grid",
              placeItems: "center",
            }}
          >
            <h2 style={{ color: "red" }}>
              K value is not available!
              <h4 style={{ textTransform: "capitalize" }}>
                please contact the support team. <br /> Tel: +91 9769847865
              </h4>
            </h2>
          </Paper>
        ) : null}
        {isKvalue && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={350} thickness={2}></CircularProgress>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {!isKvalue ? (
          <Paper
            sx={{
              textTransform: "capitalize",
              height: "40%",
              width: "70%",
              margin: "30px auto",
              p: 2,
              display: "grid",
              placeItems: "center",
            }}
          >
            <h2 style={{ color: "red" }}>
              K value is not available!
              <h4 style={{ textTransform: "capitalize" }}>
                please contact the support team. <br /> Tel: +91 9769847865
              </h4>
            </h2>
          </Paper>
        ) : null}
        {isKvalue && (
          <>
            {reportDetails.length > 0 && (
              <Toolbar
                sx={{ displayPrint: "none" }}
                style={{ padding: "0px", width: "100%" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{ ml: 3, displayPrint: "none" }}
                  onClick={() => generatePDF()}
                >
                  Budget Pdf
                </Button>
              </Toolbar>
            )}
            <div ref={printComponentRef}>
              {reportDetails &&
                reportDetails.map((u, idx) => (
                  <div key={`uncertainty-${idx}`}>
                    {u[0]?.[0]?.length > 0 ? (
                      <>
                        <div>{buildTypeA(u[0][0])}</div>
                        <Divider
                          sx={{ mb: 1, mt: 2, displayPrint: "none" }}
                        ></Divider>
                      </>
                    ) : (
                      ""
                    )}
                    {u[0]?.[1]?.length > 0 ? (
                      <>
                        <div>{buildTypeA(u[0][1], true)}</div>
                        <Divider
                          sx={{ mb: 1, mt: 2, displayPrint: "none" }}
                        ></Divider>
                      </>
                    ) : (
                      ""
                    )}
                    <div>{u[1] && buildTypeB(u[1])}</div>
                    <div>{u[2][0] && buildBudget(u[2][0], u[2][1])}</div>
                  </div>
                ))}
              <div style={{ width: "100%" }} id="header-section"></div>
            </div>
          </>
        )}

        {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Budget"}
          />
        )}
      </>
    );
  }
}
