import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Button, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { helpmoduleGuide } from "./guide";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
      color: "black",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Help = () => {
  const helpmoduleGuides = helpmoduleGuide();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ width: "95%", margin: "auto" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              helpmoduleGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ boxShadow: "1px 1px 5px #000" }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{ backgroundColor: "#ccc"}}
        >
          <Typography>Color code for Certificate</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              paddingLeft: "20px",
              flexDirection: "column",
            }}
            id="help_section_accordion"
          >

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div
                style={{
                  backgroundColor: "rgb(108 231 150)",
                  width: "15px",
                  height: "15px",
                }}
              >
                {" "}
              </div>{" "}
              &nbsp; &nbsp; <p>Certificate is Signed</p>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div
                style={{
                  backgroundColor: "rgb(223 87 223)",
                  width: "15px",
                  height: "15px",
                }}
              >
                {" "}
              </div>{" "}
              &nbsp; &nbsp; <p>Certificate is Reviewed</p>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div
                style={{
                  backgroundColor: "#f37c4f",
                  width: "15px",
                  height: "15px",
                }}
              >
                {" "}
              </div>{" "}
              &nbsp; &nbsp; <p>Certificate is Witnessed</p>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div
                style={{
                  backgroundColor: "rgb(123 123 223)",
                  width: "15px",
                  height: "15px",
                }}
              >
                {" "}
              </div>{" "}
              &nbsp; &nbsp; <p>Certificate is Approved</p>{" "}
            </div>
          </Box>
        </AccordionDetails>
      </Accordion>

      <div style={{ textAlign: "left", margin: "20px 0px" }}>
        <h5>Application Term Glossary</h5>
        <br />
        <h6>NA : Not Available</h6>
      </div>
    </div>
  );
};

export default Help;
