import React, { Component, useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";
import HelpIcon from "@mui/icons-material/Help";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useParams } from "react-router";
import DisplayTable from "./displayTable";
import { createInstrumentGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { allSettings } from "../../../utils/components/allSettings";
import ErrorModal from "../../../utils/components/errorModal";

const mode = ["Source", "Measure"];

const parameters = {
  Source: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @",
    "DC Power",
    "Conductivity meter",
    "pH Meter",
  ],
  Measure: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)",
  ],
};

const paratype = {
  Source: {
    Oscilloscope: [
      "Amplitude",
      "AC Amplitude @ 50 Hz",
      "DC Amplitude",
      "Time Base",
      "Frequency",
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
    ],
  },
  Measure: {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)",
    ],
  },
};

let gaugeArr = [
  { id: 1, label: "Plain plug Gauge" },
  { id: 2, label: "Width gauge" },
  { id: 3, label: "Paddle gauge" },
  { id: 4, label: "Flush pin" },
  { id: 5, label: "Snap gauge" },
];

//column object format
/*
 * column ={
      table,
 *    field 
 * }
 */

const fixedColumns = [
  [
    {
      field: "id",
      headerName: "Sr. No.",
      editable: false,
    },
    {
      field: "mode",
      headerName: "Mode",
      editable: true,
      toggle: 1,
    },
    {
      field: "parameter",
      headerName: "Parameter",
      editable: true,
      toggle: 1,
    },
    {
      field: "paratype",
      headerName: "Parameter Type",
      editable: true,
      toggle: 1,
    },
    {
      field: "fromRange",
      headerName: "From range",
      editable: true,
    },
    {
      field: "toRange",
      headerName: "To range",
      editable: true,
    },
    {
      field: "CMCPLUS",
      headerName: "Lower CMC",
      editable: true,
    },
    {
      field: "CMSMINUS",
      headerName: "Higher CMC",
      editable: true,
    },
    {
      field: "lc",
      headerName: "Least Count",
      editable: true,
    },
    {
      field: "Action",
      headerName: "Action",
    },
  ],
];

const cmcColumnMapping = {};

const CreateInstrument = (props) => {
  const createInstrumentGuides = createInstrumentGuide();
  const [state, setState2] = useState({
    standards: [],
    filteredStandards: [],
    disciplineValue: {},
    uncertaintyMasterData: [],
    units: [],
    finalUnitArray: [],
    disciplines: [],
    disciplineArr: [],
    disciplineId: null,
    instrumentNameId: null,
    calibrationProcedureNo: null,
    referenceStandards: null,
    standardMaster: null,
    supportiveIds: null,
    uncertaintyMaster: [],
    standardMasterIdArray: null,
    updateEnableDisableO: null,
    readingRows: [["", "", "", "", "", "", "", "", ""]],
    readingRangeValue: [["", "", "", "", "", "", "", "", ""]],
    et: true,
    readingColumns: fixedColumns,
    supportive: null,
    formatNumber: "",
    revisionNumber: "",
    revisionDate: "",
  });
  const params = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [accreditationOptions, setAccreditationOptions] = useState([]);
  const [accreditationType, setAccreditationType] = useState(1);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [remarksData, setRemarksData] = useState([]);
  const[commonUncertaintyFactors,setCommonUncertaintyFactors] = useState([]);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const setState = (val) => {
    setState2({
      ...state,
      ...val,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const refresh = () => {
    window.location.reload(false);
  };

  const filterStandardsByDiscipline = (inpt, dsid) => {
    if (dsid) return inpt.filter((e) => e.disciplineId === dsid);
    return inpt;
  };

  const retErr = (errTxt, err) => {
    console.log(errTxt, err);
    return "err";
  };

  const getData = () => {
    Promise.all(
      [
        fetchDeciplineData(),
        fetchStandardsData(),
        fetchSupportiveData(),
        fetchUncertinityData(),
        getUnitMasterArray(),
      ].concat(params.id ? [fetchInstrumentData(), getCmcReadings()] : [])
    )
      .then((res) => {
        let disciplineArr = [];
        for (var i = 0; i < res[0].length; i++) {
          disciplineArr.push({
            label: res[0][i].name,
            id: res[0][i].id,
          });
        }
        let st = {
          disciplines: res[0],
          disciplineArr: disciplineArr,
          standards: res[1],
          filteredStandards: res[1],
          supportive: res[2].map((item) => {
            return {
              id: item.pl_id,
              standardRangesId: item.pr_id,
              title: item.pl_standardName,
              range: item.pr_rangeName,
            };
          }),
          uncertaintyMasterData: res[3],
          units: res[4],
        };
        if (params.id) {
          let instr = { ...res[5] };
          setCommonUncertaintyFactors(instr?.inst_commonUncertaintyFactors?.split(","))
          // setAccreditationType(instr?.inst_accreditationType)
          let sim = [[], []];
          (instr.inst_supportiveInstrumentMasterData || "")
            .split(",")
            .map((e) => {
              sim[0].push(parseInt(e.split(":")[0]));
              sim[1].push(parseInt(e.split(":")[1]));
            });
          let cmcReadings = res[6];
          let rows = [];
          let rowsSpace = [];
          try {
            for (let i = 0; i < cmcReadings.length; i++) {
              rowsSpace.push([
                cmcReadings[i].id,
                "",
                "",
                "",
                (cmcReadings[i].fromRange || "#").split("#")[1],
                (cmcReadings[i].toRange || "#").split("#")[1],
                (cmcReadings[i].lowerCmc || "#").split("#")[1],
                (cmcReadings[i].higherCmc || "#").split("#")[1],
                (cmcReadings[i].lc || "#").split("#")[1],
              ]);
              rows.push([
                cmcReadings[i].id,
                (cmcReadings[i].mode || "#").split("#")[0],
                (cmcReadings[i].parameter || "#").split("#")[0],
                (cmcReadings[i].paratype || "#").split("#")[0],
                (cmcReadings[i].fromRange || "#").split("#")[0],
                (cmcReadings[i].toRange || "#").split("#")[0],
                (cmcReadings[i].lowerCmc || "#").split("#")[0],
                (cmcReadings[i].higherCmc || "#").split("#")[0],
                (cmcReadings[i].lc || "#").split("#")[0],
              ]); 
            }
          } catch (error) {
            console.log(error);
          }
          let disciplineKeys = false;
          st.disciplines.forEach((el) => {
            if (el.id == instr.ds_id) {
              if (el.disciplineKey == "0") {
                disciplineKeys = true;
              }
            }
          });
          if (disciplineKeys) {
            st.et = false;
          } else {
            st.et = true;
          }
          const unitArr = [""];
          for (let i = 0; i < st.units.length; i++) {
            if (parseInt(instr.ds_id) == st.units[i].disciplineId) {
              unitArr.push(st.units[i]);
            }
          }
          st.finalUnitArray = unitArr;

          st = {
            ...st,
            disciplineValue: {
              id: instr.ds_id,
              label: instr.ds_name,
            },
            instrumentNameId: instr.inst_instrumentName,
            calibrationProcedureNo: instr.inst_calibrationProcedureNo,
            referenceStandards: instr.inst_referenceStandards,
            formatNumber: instr.inst_formatNumber,
            revisionNumber: instr.inst_revisionNumber,
            remarkId: instr.inst_remarkId,
            revisionDate: instr.inst_revisionDate,
            updateEnableDisableO: gaugeArr.filter(
              (e) => instr.inst_isGauge == e.id
            )[0],
            supportiveIds: st.supportive.filter(
              (s) =>
                sim?.[0]?.includes(s.id) &&
                sim?.[1]?.includes(s.standardRangesId)
            ),
            standardMasterIdArray: instr.inst_uncertaintyFactors,
            readingRows: rows,
            readingRangeValue: rowsSpace,
          };
        }
        setState({ ...st });
      })
      .catch((err) =>{
        console.log("data error : ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getData" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchInstrumentData = () => {
    let url = BASE_URL;
    return axiosWithToken
      .get(
        url +
          `xjoin?_join=inst.instruments,_j,ds.discipline&_on1=(inst.disciplineId,eq,ds.id)&_fields=ds.name,ds.id,inst.disciplineId,inst.id,inst.standardMasterIds,inst.uncertaintyFactors,inst.commonUncertaintyFactors,inst.supportiveInstrumentMasterData,inst.accreditationType,inst.instrumentName,inst.calibrationProcedureNo,inst.referenceStandards,inst.isGauge,inst.formatNumber,inst.remarkId,inst.revisionNumber,inst.revisionDate&_where=(inst.id,eq,${params.id})`
      )
      .then((res) => {
        if (res.data.length) return res.data[0];
        return axiosWithToken
          .get(
            BASE_URL +
              `instruments?_fields=formatNumber,revisionNumber,remarkId,commonUncertaintyFactors,revisionDate,disciplineId,id,standardMasterIds,uncertaintyFactors,supportiveInstrumentMasterData,accreditationType,instrumentName,calibrationProcedureNo,referenceStandards,isGauge&_where=(id,eq,${params.id})`
          )
          .then((res) => {
            return res.data.map((e) => ({
              inst_disciplineId: e.disciplineId,
              inst_id: e.id,
              inst_standardMasterIds: e.standardMasterIds,
              inst_uncertaintyFactors: e.uncertaintyFactors,
              inst_accreditationType: e.accreditationType,
              inst_commonUncertaintyFactors: e.commonUncertaintyFactors,
              inst_supportiveInstrumentMasterData:
                e.supportiveInstrumentMasterData,
              inst_instrumentName: e.instrumentName,
              inst_calibrationProcedureNo: e.calibrationProcedureNo,
              inst_referenceStandards: e.referenceStandards,
              inst_isGauge: e.isGauge,
              inst_formatNumber: e.formatNumber,
              inst_revisionNumber: e.revisionNumber,
              inst_remarkId: e.remarkId,
              inst_revisionDate: e.revisionDate,
            }))[0];
          });
      })
      .catch((err) => {
        retErr("courses data error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchInstrumentData" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
      });
  };

  const getCmcReadings = () => {
    return axiosWithToken
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${params.id})~and(accreditationType,eq,${accreditationType})`)
      .then((res) => {
        return res.data;
      })
      .catch((err) =>{
        retErr("Something Went Wrong while fetching standardRanges!", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getCmcReadings" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
      }
      );
  };

  const fetchDeciplineData = () => {
    let url = BASE_URL;
    return axiosWithToken
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => res.data)
      .catch((err) => {
        retErr("disciplines data error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchDeciplineData" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchStandardsData = () => {
    let url = BASE_URL;
    return axiosWithToken
      .get(
        url +
          "standards?_fields=disciplineId,standardName,validUpto,id,type,stId&_where=(status,eq,1)"
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        retErr("stabdards data error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchStandardsData" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchSupportiveData = () => {
    let url = BASE_URL;
    return axiosWithToken
      .get(
        url +
          "xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.type,eq,2)"
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        retErr("supportive data error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchSupportiveData" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchUncertinityData = () => {
    let url = BASE_URL;
    return axiosWithToken
      .get(url + "uncertainty?_where=(status,eq,1)")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
      retErr("uncertainty data error: ", err);
      toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchUncertinityData" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
    });
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    return axiosWithToken
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        retErr("unit data error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getUnitMasterArray" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
      });
  };

  const onSubmit = (e) => {
    let standardMasterIdArray = [];
    (state.standardMasterIdArray || "").split("|").forEach((element, index) => {
      standardMasterIdArray = [
        ...standardMasterIdArray,
        String(element.split(":")[0]),
      ];
    });
    standardMasterIdArray = standardMasterIdArray.join(",");

    let url = BASE_URL;
    var jsonData = {
      disciplineId: state.disciplineValue.id,
      instrumentName: state.instrumentNameId,
      standardMasterIds: standardMasterIdArray,
      calibrationProcedureNo: state.calibrationProcedureNo,
      referenceStandards: state.referenceStandards,
      formatNumber: state.formatNumber,
      revisionNumber: state.revisionNumber,
      remarkId: state.remarkId,
      accreditationType:accreditationType,
       commonUncertaintyFactors: commonUncertaintyFactors?.join(","),
      revisionDate: state.revisionDate
        ? moment(state.revisionDate).format("YYYY-MM-DD")
        : null,
      uncertaintyFactors: state.standardMasterIdArray,
      // supportiveInstrumentMasterData: (state.supportiveIds || [])
      //   .map((e) => `${e.id}:${e.standardRangesId}`)
      //   .join(","),
      isGauge: state.updateEnableDisableO
        ? state.updateEnableDisableO.id
        : null,
    };

    if (params.id)
      axiosWithToken
        .patch(url + `instruments/${params.id}`, jsonData)
        .then((res) => {
          submitRanges(params.id);
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
        });
    else
      axiosWithToken
        .post(url + "instruments", jsonData)
        .then((res) => {
          submitRanges(res.data.insertId);
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
        });
  };

  const submitRanges = (id) => {
    if (state.readingRows.length) {
      let rows = state.readingRows.map((row, i) => ({
        instrumentId: id,
        accreditationType:accreditationType,
        mode:
          state.readingRangeValue[i][1] && row[1]
            ? row[1] + "#" + state.readingRangeValue[i][1]
            : row[1],
        parameter:
          state.readingRangeValue[i][2] && row[2]
            ? row[2] + "#" + state.readingRangeValue[i][2]
            : row[2],
        paratype:
          state.readingRangeValue[i][3] && row[3]
            ? row[3] + "#" + state.readingRangeValue[i][3]
            : row[3],
        fromRange:
          state.readingRangeValue[i][4] && row[4]
            ? row[4] + "#" + state.readingRangeValue[i][4]
            : row[4],
        toRange:
          state.readingRangeValue[i][5] && row[5]
            ? row[5] + "#" + state.readingRangeValue[i][5]
            : row[5],
        lowerCmc:
          state.readingRangeValue[i][6] && row[6]
            ? row[6] + "#" + state.readingRangeValue[i][6]
            : row[6],
        higherCmc:
          state.readingRangeValue[i][7] && row[7]
            ? row[7] + "#" + state.readingRangeValue[i][7]
            : row[7],
        lc:
          state.readingRangeValue[i][8] && row[8]
            ? row[8] + "#" + state.readingRangeValue[i][8]
            : row[8],    
      }));
      let post = [],
        postIndexes = [],
        patch = [];
      rows.map((r, i) => {
        if (state.readingRows[i][0] === "") {
          post.push(r);
          postIndexes.push(i);
        } else {
          patch.push([state.readingRows[i][0], r]);
          postIndexes.push(undefined);
        }
      });
      Promise.all(
        (post.length > 0
          ? [axiosWithToken.post(BASE_URL + "cmc/bulk", post)]
          : []
        ).concat(
          patch.length > 0
            ? [
                ...patch.map((p) =>
                  axiosWithToken.patch(BASE_URL + `cmc/${p[0]}`, p[1])
                ),
              ]
            : []
        )
      )
        .then((res) => {
          if (params.id === undefined) {
            toast.success("Instrument Created Successfully!");
            setTimeout(() => {
              refresh();
            }, 500);
          } else if (post.length > 0) {
            toast.success("Instrument Updated Successfully!");
            let newRows = [...state.readingRows];
            let idx = 0;
            postIndexes.map((i) => {
              if (i !== undefined) {
                newRows[i].id = res[idx];
                idx += 1;
              }
            });
            setState({
              readingRows: newRows,
            });
          } else toast.success("Instrument Updated Successfully!");
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "submitRanges" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
        });
    } else if (params.id !== undefined) refresh();
  };

  useEffect(() => {
    getData();
  }, [accreditationType]);

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...state.readingRangeValue];
    newRows[rowIndex][colIndex] = value;
    setState({
      readingRangeValue: [...newRows],
    });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...state.readingRows];
    newRows[rowIndex][colIndex] = value;
    setState({
      readingRows: [...newRows],
    });
  };

  // const addDescriptionRow = (tableIndex) => {
  //   let newReadingRows = [...state.readingRows];
  //   newReadingRows.push(["", "", "", "", "", "", "", ""]);
  //   let newReadingRowss = [...state.readingRangeValue];
  //   newReadingRowss.push(["", "", "", "", "", "", "", ""]);
  //   let st = {
  //     readingRows: [...newReadingRows],
  //     readingRangeValue: [...newReadingRowss],
  //   };
  //   setState({ ...st });
  // }

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...state.readingRows];
    if (tableIndex !== undefined) {
      newReadingRows.push([...state.readingRows[tableIndex]]);
      newReadingRows[newReadingRows.length - 1][0] = "";
    } else newReadingRows.push(["", "", "", "", "", "", "", "", ""]);

    let newReadingRowss = [...state.readingRangeValue];
    if (tableIndex !== undefined)
      newReadingRowss.push([...state.readingRangeValue[tableIndex]]);
    else newReadingRowss.push([null, null, null, null, null, null, null, null, null]);

    let st = {
      readingRows: [...newReadingRows],
      readingRangeValue: [...newReadingRowss],
    };
    setState({ ...st });
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...state.readingRows];
    if (newReadingRows[rowIndex][0]) {
      axiosWithToken
        .delete(BASE_URL + `cmc/${newReadingRows[rowIndex][0]}`)
        .then((res) => {
          // console.log("cmc deleted succesfully!");
          toast.success("cmc deleted succesfully!");
          newReadingRows.splice(rowIndex, 1);
          setState({
            readingRows: [...newReadingRows],
          });
        })
        .catch((err) => {
          console.log("cmc deleted error : ", err);
          toast.error(<h6 onClick={() => openErrorModal(err.message, "deleteReadingRow" ,"CreateInstrument")}><u>Error: {err.message}</u></h6>);
        });
    } else {
      toast.success("cmc deleted succesfully!");
      newReadingRows.splice(rowIndex, 1);
      setState({
        readingRows: [...newReadingRows],
      });
    }
  };

  function getRemarksData() {
    let paylod = {
      query: `SELECT *  FROM remarks`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, paylod)
      .then((res) => {
        let newData = res.data;
        setRemarksData(newData);
      })
      .catch((err) => {
        console.log("remarks data fetching error: ", err);
      });
  }

  useEffect(() => {
    if (params.id) {
      let newFilterStandards = filterStandardsByDiscipline(
        state?.standards,
        state?.disciplineValue?.id
      );
      setState2((prevState) => ({
        ...prevState,
        filteredStandards: newFilterStandards,
      }));
    }
  }, [state?.disciplineValue?.id]);

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const AllSettings = await allSettings();
        let filterAccreditationType = AllSettings?.find(
          (ele) => ele.keyName == "accreditationType"
        )?.value?.split(",");
        const filteredOptions = filterAccreditationType.map((label, index) => ({
          id: index + 1,
          label: label.trim(),
        }));
        setAccreditationOptions(filteredOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSetting();
  }, []);

useEffect(()=>{
  getRemarksData()
},[])

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create new instrument
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createInstrumentGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Autocomplete
            disabled={!isNaN(Number(params.id))}
            size="small"
            id="instrumentlist_create_discipline"
            options={[...state.disciplineArr]}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            defaultValue={undefined}
            value={state.disciplineValue || {}}
            getOptionLabel={(option) => option.label || ""}
            onChange={(event, value) => {
              let st = {};
              if (value != null) {
                let disciplineKeys = false;
                state.disciplines.forEach((el) => {
                  if (el.id == value.id) {
                    if (el.disciplineKey == "0") {
                      disciplineKeys = true;
                    }
                  }
                });
                if (disciplineKeys) {
                  st.et = false;
                } else {
                  st.et = true;
                }
                const unitArr = [""];
                for (let i = 0; i < state.units.length; i++) {
                  if (parseInt(value.id) == state.units[i].disciplineId) {
                    unitArr.push(state.units[i]);
                  }
                }
                st.finalUnitArray = unitArr;
                st.disciplineId = value.id;
                st.filteredStandards = filterStandardsByDiscipline(
                  state.standards,
                  value.id
                );
              } else {
                st.disciplineId = null;
              }
              st.disciplineValue = value;
              setState({ ...st });
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="instrumentlist_create_instrumentname"
            label="Instrument name *"
            size="small"
            fullWidth
            variant="outlined"
            value={state.instrumentNameId || ""}
            onChange={(e) => {
              setState({
                instrumentNameId: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="instrumentlist_create_calibproced"
            label="Calibration Procedure No."
            size="small"
            fullWidth
            variant="outlined"
            value={state.calibrationProcedureNo || ""}
            onChange={(e) => {
              setState({
                calibrationProcedureNo: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="instrumentlist_create_master_issdtandards"
            label="Is Standards"
            size="small"
            fullWidth
            variant="outlined"
            value={state.referenceStandards || ""}
            onChange={(e) => {
              setState({
                referenceStandards: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Autocomplete
            size="small"
            id="instrumentlist_create_gauage"
            onChange={(_, val) => {
              setState({
                updateEnableDisableO: val,
              });
            }}
            value={state.updateEnableDisableO}
            options={gaugeArr}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Gauge" />}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="instrumentlist_create_formateno"
            label="Format Number"
            size="small"
            fullWidth
            variant="outlined"
            value={state.formatNumber || ""}
            onChange={(e) => {
              setState({
                formatNumber: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="instrumentlist_create_reviosno"
            label="Revision Number"
            size="small"
            fullWidth
            variant="outlined"
            value={state.revisionNumber || ""}
            onChange={(e) => {
              setState({
                revisionNumber: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={3}
          id="instrumentlist_create_revisondate"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Revision Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={state.revisionDate ? new Date(state.revisionDate) : ""}
              onChange={(newValue) => {
                setState({
                  revisionDate: newValue,
                });
              }}
              // onChange={(newValue) => {
              //   updateQuoObject("revisionDate", newValue);
              // }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid  
         item
         xs={6}
         sm={6}
         md={4}
         lg={3}
        >
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={remarksData}
                getOptionLabel={(option) => option.label}
                value={
                  remarksData.find(
                    (lab) =>
                      lab.id ===
                      state?.remarkId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Remark" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    setState({
                      remarkId: value?.id,
                    });
                  } else {
                    setState({
                      remarkId:"",
                    });
                  }
                }}
              />
            </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DisplayTable
            value={state.standardMasterIdArray}
            filteredStandards={state.filteredStandards}
            allStandards={state.standards}
            uncertaintyMasterData={state.uncertaintyMasterData}
            disciplineId={state.disciplineId}
            setState={setState}
            commonUncertaintyFactors={commonUncertaintyFactors}
            setCommonUncertaintyFactors={setCommonUncertaintyFactors}
          />
          {/* } */}
        </Grid>
      </Grid>

      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>CMC</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <Autocomplete
          key={accreditationOptions.length} 
          options={accreditationOptions}
          size="small"
          sx={{ minWidth: "210px" }}
          getOptionLabel={(option) => option.label}
          value={accreditationOptions?.find(
            (ele) => ele.id === accreditationType
          )}
          onChange={(event, selectedOption) => {
            setAccreditationType(selectedOption ? selectedOption.id : 1);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Accreditation Type" />
          )}
        />

        <Button
          id="instrumentlist_create_addnablscope"
          startIcon={<AddIcon />}
          onClick={(e) => {
            addDescriptionRow();
          }}
          variant="contained"
          size="small"
        >
          Add CMC
        </Button>
      </div>

      <div
        style={{ width: "100%", overflow: "auto" }}
        id="instrumentlist_create_nablscope"
      >
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {state.readingColumns[0]
                  .filter((e) => e.toggle === undefined || !state.et)
                  .map((column) => (
                    <TableCell key={column.field}>
                      {" "}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {state.readingRows.map((row, index) => ( */}
              {state.readingRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {index + 1}

                      <Button
                        id="instrumentlist_create_copynabl"
                        onClick={(e) => {
                          addDescriptionRow(index);
                        }}
                      >
                        <ContentCopyIcon />
                      </Button>
                    </TableCell>
                    {row.map((cell, cellIndex) => {
                      if (
                        cellIndex != 0 &&
                        cellIndex != 1 &&
                        cellIndex != 2 &&
                        cellIndex != 3
                      ) {
                        return (
                          <TableCell>
                            <TextField
                              id="instrumentlist_create_nablevalue"
                              size="small"
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            ></TextField>
                            <br />
                            <select
                              id="instrumentlist_create_nablunit"
                              // disabled={plus}
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            >
                              <option value={""} key={""} selected>
                                {""}
                              </option>
                              {state.finalUnitArray?.map((unit) => {
                                if (
                                  unit.symbol ==
                                  state.readingRangeValue[index][cellIndex]
                                ) {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                      selected
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 1 && !state.et) {
                        return (
                          <TableCell>
                            <Autocomplete
                              style={{ width: "150px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={mode.map((e) => ({
                                label: e,
                              }))}
                              renderInput={(params) => (
                                <TextField {...params} label="Mode *" />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                              // disabled={plus}
                            />
                            <select
                              disabled={true}
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            >
                              <option value={""} key={""} selected>
                                {""}
                              </option>
                              {state.finalUnitArray?.map((unit) => {
                                if (
                                  unit.symbol ==
                                  state.readingRangeValue[index][cellIndex]
                                ) {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                      selected
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 2 && !state.et) {
                        return (
                          <TableCell>
                            <Autocomplete
                              style={{ width: "250px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={
                                parameters[row[1]]
                                  ? parameters[row[1]].map((e) => ({
                                      label: e,
                                    }))
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Parameter *" />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                              // disabled={(plus || !row[2]) ? true : false}
                              disabled={!row[1] ? true : false}
                            />
                            <select
                              disabled={true}
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            >
                              <option value={""} key={""} selected>
                                {""}
                              </option>
                              {state.finalUnitArray?.map((unit) => {
                                if (
                                  unit.symbol ==
                                  state.readingRangeValue[index][cellIndex]
                                ) {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                      selected
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 3 && !state.et) {
                        return (
                          <TableCell>
                            {!(paratype[row[1]] && paratype[row[1]][row[2]]) ? (
                              <TextField
                                size="small"
                                value={cell}
                                onChange={(e) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    e.target.value
                                  );
                                }}
                                disabled={!row[2]}
                              ></TextField>
                            ) : (
                              <Autocomplete
                                style={{ width: "150px" }}
                                size="small"
                                id="combo-box-demo"
                                value={cell}
                                options={
                                  paratype[row[1]] && paratype[row[1]][row[2]]
                                    ? paratype[row[1]][row[2]].map((e) => ({
                                        label: e,
                                      }))
                                    : []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Parameter Type *"
                                  />
                                )}
                                onInputChange={(event, newInputValue) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    newInputValue
                                  );
                                }}
                                // disabled={
                                //   plus ||
                                //   !(paratype[row[3]] && paratype[row[3]][row[4]])
                                //     ? true
                                //     : false
                                // }
                              />
                            )}
                            <select
                              id="instrumentlist_create_nablunit"
                              disabled={true}
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            >
                              <option value={""} key={""} selected>
                                {""}
                              </option>
                              {state.finalUnitArray?.map((unit) => {
                                if (
                                  unit.symbol ==
                                  state.readingRangeValue[index][cellIndex]
                                ) {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                      selected
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option
                                      value={unit.symbol}
                                      key={unit.symbol}
                                    >
                                      {unit.symbol}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </TableCell>
                        );
                      }
                    })}
                    <TableCell>
                      <DeleteIcon
                        id="instrumentlist_create_nabl_delete"
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteReadingRow(index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={state.readingRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ClassicTable>
      </div>

      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="instrumentlist_create_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={(e) => {
            onSubmit(e);
          }}
        >
          {params.id ? "Update" : "Save"}
        </Button>
      </Toolbar>
      {/* <ToastContainer /> */}
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Paper>
  );
};

export default CreateInstrument;
