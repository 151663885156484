export const showComponent = (operation) => {
  let _userType = localStorage.getItem("type");
  if (_userType == "1" || _userType == "4") return true;
  else if (_userType == "2" ||_userType == "5" || _userType == "6" ) {
    return true;
    // if (operation === "delete") return false
    // else return true
  } else if (_userType == "3") {
    if (operation === "print" || operation === "show") return true;
    else return false;
  }
  return false;
};

const DEFAULT_CHUNK_SIZE = 5;

export const makeChunks = (arr, chunkSize) => {
  const chunks = [];
  while (arr.length) {
    chunkSize = Math.min(arr.length, chunkSize || DEFAULT_CHUNK_SIZE);
    const chunk = arr.slice(0, chunkSize);
    chunks.push(chunk);
    arr = arr.slice(chunkSize);
  }
  return chunks;
};
