import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ButtonGroup,
  Tooltip,
  Input,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";

import { ClassicTable } from "../../../utils/components/Styles";
import { showComponent } from "../../helper/helpers";
import { BASE_URL } from "../../../global";

import {
  fetchDescriptionRows,
  useUnitMaster,
  useInstruments,
  useSettings,
} from "./fetchHandlers";
import { RenderModal, RenderMultivalueModal } from "./modals";
import { fixedColumns } from "./constants";
import { ImportExcelModal } from "./importExcelModal";
import SrfRowDetailsModal from "./srfRowDetailsModal";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";



export const DUCInwardDetails = (props) => {
  const {
    id,
    readingRows,
    setReadingRows,
    srfInstrumentsToDelete,
    setSrfInstrumentsToDelete,
    srfDetails,
    updateSrfDetails,
    openErrorModal
  } = props;

  const [copyQt, setCopyQt] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [multiValueModalOpen, setMultiValueModalOpen] = useState(false);
  const [multiValueData, setMultiValueData] = useState({});
  const [excelImport, setExcelImport] = useState(false);
  const [additionalReadingColumns, setAdditionalReadingColumns] = useState([]);
  const [tempReading, setTempReading] = useState([]);
  const [currentRowIndex, setCurrentRowIndex] = useState(-1);

  const [unitArray, unitFilter, setUnitFilter, fetchUnitMaster] = useUnitMaster(
    []
  );
  const [instrumentList, instrumentArray, fetchInstrumentsList, getInstrument] =
    useInstruments([]);
  const [settings, fetchSettings] = useSettings({});
  const [DUC_ID, set_DUIC_ID] = useState(-1);
  const [openSRFModal, setOpenSRFModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [materialTypeOption, setMaterialTypeOption] = useState([]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const updateCellValue = (rowIndex, key, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][key] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let row = {
      srfsId: id,
    };
    for (let i = 0; i < fixedColumns.length; i++) {
      if (["id", "calibrationType"].includes(fixedColumns[i].key)) {
        row[fixedColumns[i].key] = 0;
      } else {
        row[fixedColumns[i].key] = "";
      }
    }

    row["extraColumns"] = {};
    for (let i = 0; i < additionalReadingColumns.length; i++) {
      row["extraColumns"][additionalReadingColumns[i].key] = "";
    }

    setReadingRows([...readingRows, row]);
    setCopyQt([...copyQt, 1]);
  };

  const deleteDescriptionRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    setSrfInstrumentsToDelete([
      ...srfInstrumentsToDelete,
      newReadingRows[rowIndex].id,
    ]);
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
    let tmp = [...copyQt];
    tmp.splice(rowIndex, 1);
    setCopyQt([...tmp]);
  };

  const copyDescriptionRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    let newRows = [...Array(copyQt[rowIndex])].fill(0).map((e) => {
      return { ...newReadingRows[rowIndex], id: 0 };
    });
    newReadingRows.splice(rowIndex + 1, 0, ...newRows);
    setReadingRows([...newReadingRows]);

    let tmp = [...copyQt];
    tmp.splice(
      rowIndex + 1,
      0,
      ...Array(copyQt[rowIndex])
        .fill(0)
        .map((e) => 1)
    );
    tmp[rowIndex] = 1;
    setCopyQt(tmp);
  };

  const checkDucMasterRecord = (id, sr, di, index) => {
    
    let DUCID =encodeURIComponent(di).replace(/%2C/g, ',')
 
    // TODO: is exipred logic applicable here too?
    let url = BASE_URL;
    let where = `_where=(instrumentId,eq,${id})`;
    if (sr)
      where +=
        where.length > 0
          ? `~and(serialNumber,eq,${sr})`
          : `(serialNumber,eq,${sr})`;
    if (DUCID)
      where += where.length > 0 ? `~and(DUCID,eq,${DUCID})` : `(DUCID,eq,${DUCID})`;
    let limit = where.length > 0 ? "&_" : "";
    limit += "sort=-id&_size=1";
    axiosWithToken
      .get(url + `ducMaster?${where}${limit}`)
      .then((res) => {
        if (res.data.length === 0) {
          return;
        }
        let row = readingRows[index];
        // TODO: reset data if record not found
        row = {
          ...row,
          DUCID: res.data[0].DUCID,
          serialNumber: res.data[0].serialNumber,
          ranges: res.data[0].ranges,
          lc: res.data[0].lc,
          make: res.data[0].make,
          model: res.data[0].model,
          calFrequency: res.data[0].calFrequency,
          accuracy: res.data[0].accuracy,
          locationOfInstrument: res.data[0].locationOfInstrument,
          department: res.data[0].department,
          operatingRange: res.data[0].operatingRange,
        };

        if (res?.data[0]?.extraColumns !== null && res?.data[0]?.extraColumns) {
          row.extraColumns = JSON.parse(res?.data[0]?.extraColumns);
        }

        let newRows = [...readingRows];
        newRows[index] = row;
        setReadingRows([...newRows]);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "checkDucMasterRecord" ,"DucInwardDetails")}><u>Error: {err.message}</u></h6>);
      });
  };

  const checkLatestInstrumentRecord = (id, sr, di, index) => {
    let DUCID =encodeURIComponent(di).replace(/%2C/g, ',')
    let url = BASE_URL;
    let where = `where=(ins.instrumentId,eq,${id})`;
    if (sr)
      where +=
        where.length > 0
          ? `~and(ins.serialNumber,eq,${sr})`
          : `(ins.serialNumber,eq,${sr})`;
    if (DUCID)
      where +=
        where.length > 0 ? `~and(ins.DUCID,eq,${DUCID})` : `(ins.DUCID,eq,${DUCID})`;
    let limit = where.length > 0 ? "&_" : "";
    limit += "sort=-ins.id&_size=1";
    axiosWithToken
      .get(
        url +
          `xjoin?_join=ds.datasheets,_j,ins.srfInstruments&_on1=(ds.id,eq,ins.id)&_${where}${limit}&_fields=ins.instrumentId,ins.DUCID,ins.serialNumber,ins.ranges,ins.lc,ins.make,ins.model,ins.operatingRange,ins.department,ins.ConOfDuc,ins.calFrequency,ins.accuracy,ins.calibrationType,ins.location,ins.locationOfInstrument,ins.calPoint,ins.calMethod,ins.labCapabilities,ins.extraColumns,ds.nextDueDate,ds.id,ins.id`
      )
      .then((res) => {
        if (res.data.length === 0) {
          return;
        }
        // setSrfInstruments(res.data);
        let row = [];
        if (res.data.length === 1) {
          let expired = new Date(res.data[0].ds_nextDueDate) > new Date();
          row = {
            id: 0,
            instrumentId: res.data[0].ins_instrumentId,
            DUCID: res.data[0].ins_DUCID,
            serialNumber: res.data[0].ins_serialNumber,
            ranges: res.data[0].ins_ranges,
            lc: res.data[0].ins_lc,
            make: res.data[0].ins_make,
            model: res.data[0].ins_model,
            ConOfDuc: res.data[0].ins_ConOfDuc,
            calFrequency: res.data[0].ins_calFrequency,
            accuracy: res.data[0].ins_accuracy,
            calibrationType: res.data[0].ins_calibrationType,
            location: res.data[0].ins_location,
            locationOfInstrument: res.data[0].ins_locationOfInstrument,
            calPoint: res.data[0].ins_calPoint,
            calMethod: res.data[0].ins_calMethod,
            materialType: res.data[0].ins_materialType,
            department: res.data[0].ins_department,
            operatingRange: res.data[0].ins_operatingRange,
            labCapabilities: res.data[0].ins_labCapabilities,
          };
          // let extraColumns = JSON.parse(res.data[0].ins_extraColumns || "{}");
          let additionalColumns =
            settings?.srfInstrumentsAdditionalColumns?.value?.split(",");
          let resposeExtraColumns = JSON.parse(
            res.data[0].ins_extraColumns || "{}"
          );

          let extraColumns = {};

          if (additionalColumns) {
            additionalColumns.forEach((column) => {
              let trimmedColumn = column; // Remove any leading/trailing spaces use (.trim())
              if (resposeExtraColumns.hasOwnProperty(trimmedColumn)) {
                extraColumns[trimmedColumn] =
                  resposeExtraColumns[trimmedColumn];
              } else {
                extraColumns[trimmedColumn] = ""; // Add the key with an empty string value
              }
            });
          }

          let newRows = [...readingRows];
          newRows[index] = {
            ...newRows[index],
            ...row,
            extraColumns: {
              ...newRows[index].extraColumns,
              ...extraColumns,
            },
          };

          setReadingRows(newRows);
          if (expired) {
            set_DUIC_ID([res.data[0].ins_DUCID, DUC_ID[1]]);
            setCurrentRowIndex(index);
            setTimeout(() => handleOpen(), 250);
          }
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error(<h6 onClick={() => openErrorModal(err.message, "checkLatestInstrumentRecord" ,"DucInwardDetails")}><u>Error: {err.message}</u></h6>);
      });
  };


  useEffect(() => {
    const generateMaterialTypeOptions = () => {
      if (!settings || !settings.materialType) {
        return [];
      }
      return settings.materialType.value.split(',').map(option => {
        const [label, value] = option.split(':');
        return { label, value };
      });
    };

    const options = generateMaterialTypeOptions();
    setMaterialTypeOption(options);
  }, [settings?.materialType]);
  

  useEffect(() => {
    fetchDescriptionRows(id, setReadingRows);
    fetchUnitMaster();
    fetchSettings([
      "srfInstrumentsAdditionalColumns",
      "DUCMasterStatus",
      "Certificate Number",
      "serviceReqNumber",
      "srfInstrumentsAdditionalColumns",
      "ULR number",
      "ULR ID",
      "materialType",
    ]);
  }, []);

  useEffect(() => {
    if (settings.srfInstrumentsAdditionalColumns?.value) {
      let additionalReadingColumns = [];
      settings.srfInstrumentsAdditionalColumns?.value
        ?.split(",")
        .forEach((col) => {
          additionalReadingColumns.push({
            field: col,
            key: col,
            headerName: col,
            editable: true,
          });
        });
      setAdditionalReadingColumns([...additionalReadingColumns]);
    }

    if (settings.DUCMasterStatus?.value === "true") {
      fetchInstrumentsList({
        filterWithDucMaster: true,
        clientId: srfDetails?.clientId,
      });
    } else {
      fetchInstrumentsList({});
    }
  }, [settings, srfDetails?.clientId]);

  const renderSRFRow = (
    rowIndex,
    row,
    fixedColumns,
    updateCellValue,
    setMultiValueData,
    setMultiValueModalOpen,
    instrumentList,
    getInstrument,
    set_DUIC_ID,
    updateSrfDetails,
    setUnitFilter,
    excelImport,
    settings,
    DUC_ID,
    checkDucMasterRecord,
    checkLatestInstrumentRecord
  ) => {
        return (
      <>
        {fixedColumns.map((column, cellIndex) => {
          const key = column.key;
          if (["id"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                {rowIndex + 1}
              </TableCell>
            );
          } else if (
            [
              "DUCID",
              "serialNumber",
              "make",
              "model",
              "ConOfDuc",
              "locationOfInstrument",
              "labCapabilities",
              "department",
            ].includes(key)
          ) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <TextField
                  id={`editsrf-ducInwardDetails-${key}`}
                  style={{ width: "200px" }}
                  size="small"
                  value={row[key]}
                  onChange={(e) => {
                    updateCellValue(rowIndex, key, e.target.value);
                    let serialNumber =
                      key === "serialNumber"
                        ? e.target.value
                        : row["serialNumber"];
                    let DUCID = key === "DUCID" ? e.target.value : row["DUCID"];
                    if (
                      !excelImport &&
                      e.target.value.length > 0 &&
                      typeof row["instrumentId"] === "number" &&
                      ["DUCID", "serialNumber"].includes(key)
                    ) {
                      if (settings?.DUCMasterStatus?.value === "true") {
                        checkDucMasterRecord(
                          row["instrumentId"],
                          serialNumber,
                          DUCID,
                          rowIndex
                        );
                      } else {
                        checkLatestInstrumentRecord(
                          readingRows[rowIndex]["instrumentId"],
                          serialNumber,
                          DUCID,
                          rowIndex
                        );
                      }
                    }
                  }}
                ></TextField>
              </TableCell>
            );
          } else if (["instrumentId"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
                // style={{ width: "200px" }}
                id="editsrf-ducInwardDetails-instrumentId"
              >
                <Autocomplete
                  style={{ width: "200px" }}
                  defaultValue={getInstrument(row[key])}
                  disabled={row?.id !== 0} 
                  size="small"
                  id="combo-box-demo"
                  value={getInstrument(row[key])}
                  options={instrumentList}
                  renderInput={(params) => <TextField {...params} />}
                  onInputChange={(event, newInputValue) => {
                    var splArr = newInputValue.split(",");
                    updateCellValue(rowIndex, key, parseInt(splArr[0]));
                    set_DUIC_ID([DUC_ID[0], splArr[1]]);
                    if (
                      !excelImport &&
                      typeof row["instrumentId"] === "number" &&
                      ["DUCID", "serialNumber"].includes(key)
                    ) {
                      if (settings?.DUCMasterStatus?.value === "true") {
                        checkDucMasterRecord(
                          parseInt(splArr[0]),
                          row["serialNumber"],
                          row["DUCID"],
                          rowIndex
                        );
                      } else {
                        checkLatestInstrumentRecord(
                          parseInt(splArr[0]),
                          row["serialNumber"],
                          row["DUCID"],
                          rowIndex
                        );
                      }
                    }

                    let desciplineH = -1;
                    for (let i = 0; i < instrumentArray.length; i++) {
                      if (
                        parseInt(newInputValue.split(",")[0]) ==
                        instrumentArray[i].id
                      ) {
                        desciplineH = instrumentArray[i].disciplineId;
                        updateSrfDetails({
                          ...srfDetails,
                          discipline: instrumentArray[i].name,
                          calibrationProcedureNo:
                            instrumentArray[i].calibrationProcedureNo,
                        });
                      }
                    }

                    // Adding unit without filtration because I am filtering unit by disciplineId and row in (RenderMultivalueModal)
                    setUnitFilter(unitArray);
                  }}
                />
              </TableCell>
            );
          } else if (
            ["ranges", "operatingRange", "lc", "accuracy"].includes(key)
          ) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <Button
                  id={`editsrf-ducInwardDetails-${key}`}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setMultiValueData({
                      rowIndex: rowIndex,
                      key: key,
                    });
                    setMultiValueModalOpen(!multiValueModalOpen);
                  }}
                  style={{
                    backgroundColor:
                      row[key] && row[key].length > 0 ? "#d9fdd3" : "",
                  }}
                >
                  Add
                </Button>
              </TableCell>
            );
          } else if ("calFrequency".includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calFrequency">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key]}
                    options={[
                      { id: 1, label: "3 Months" },
                      { id: 2, label: "6 Months" },
                      { id: 3, label: "12 Months" },
                      { id: 3, label: "18 Months" },
                      { id: 4, label: "24 Months" },
                    ]}
                    renderInput={(params) => <TextField {...params} />}
                    onInputChange={(event, newInputValue) => {
                      updateCellValue(rowIndex, key, newInputValue);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if ("calibrationType".includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calibrationType">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={["", "NABL", "IAS", "NON NABL"][row[key]]}
                    renderInput={(params) => <TextField {...params} />}
                    options={[
                      { id: 1, label: "NABL" },
                      { id: 2, label: "IAS" },
                      { id: 3, label: "NON NABL" },
                    ]}
                    onInputChange={(event, newInputValue) => {
                      if (newInputValue === "NABL") {
                        updateCellValue(rowIndex, key, 1);
                      } else if (newInputValue === "IAS") {
                        updateCellValue(rowIndex, key, 2);
                      }
                      else if (newInputValue === "NON NABL") {
                        updateCellValue(rowIndex, key, 3);
                      }
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["location"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-location">
                  <Autocomplete
                    style={{ width: "200px" }}
                    value={row[key]}
                    size="small"
                    id="combo-box-demo"
                    options={[
                      { id: 1, label: "Onsite" },
                      { id: 2, label: "In house" },
                    ]}
                    renderInput={(params) => <TextField {...params} />}
                    onInputChange={(event, newInputValue) => {
                      updateCellValue(rowIndex, key, newInputValue);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["calPoint"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calPoint">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key]}
                    options={[
                      { id: 1, label: "As per proc" },
                      { id: 2, label: "As per customer" },
                    ]}
                    renderInput={(params) => <TextField {...params} />}
                    onInputChange={(event, newInputValue) => {
                      updateCellValue(rowIndex, key, newInputValue);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["calMethod"].includes(key)) {
                        return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calMethod">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key]}
                    options={[
                      { id: 1, label: "Direct Method" },
                      { id: 2, label: "Comparison Method" },
                      { id: 3, label: "Direct/Comparison Method" },
                    ]}
                    renderInput={(params) => <TextField {...params} />}
                    onInputChange={(event, newInputValue) => {
                      updateCellValue(rowIndex, key, newInputValue);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["materialType"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px" }}
                key={cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-materialType">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={materialTypeOption.find(option => option.value === row[key])?.label || ''}
                    options={materialTypeOption.map(option => option.label)}
                    renderInput={(params) => <TextField {...params} />}
                    onInputChange={(event, newInputValue) => {
                      const selectedOption = materialTypeOption.find(option => option.label === newInputValue);
                      if (selectedOption) {
                        updateCellValue(rowIndex, key, selectedOption.value);
                      }
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else {
            return null;
          }
        })}
        {Object.keys(row["extraColumns"] || {}).map((extraColumnKey, index) => {
          return (
            <TableCell
              style={{ minWidth: "180px", minHeight: "58px" }}
              key={index}
            >
              <TextField
                style={{ width: "200px" }}
                size="small"
                value={row["extraColumns"][extraColumnKey]}
                onChange={(e) => {
                  updateCellValue(rowIndex, "extraColumns", {
                    ...row["extraColumns"],
                    [extraColumnKey]: e.target.value,
                  });
                }}
              ></TextField>
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <div id="editsrf-ducInwardDetails">
      <h4 style={{ marginBottom: "0px", float: "left" }}>DUC Inward Details</h4>
      <br /> <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {showComponent("add_in") && (
          <Button
            id="editsrf-ducInwardDetails-addButton"
            size="small"
            variant="contained"
            onClick={(e) => {
              addDescriptionRow(0);
            }}
          >
            Add DUC
          </Button>
        )}

        {showComponent("add_in") && (
          <div id="editsrf-ducInwardDetails-uploadexcel">
            <ImportExcelModal
              fixedColumns={fixedColumns}
              setReadingRows={setReadingRows}
              settings={settings}
            />
          </div>
        )}
      </div>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {fixedColumns.map((column) => (
                  <TableCell key={column.field}>
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
                {additionalReadingColumns.map((column) => (
                  <TableCell key={column.field}>
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <TextField
                        style={{
                          width: "75px",
                          marginRight: "10px",
                        }}
                        type="number"
                        // inputProps={{ min: 1, max: 75 }}
                        InputProps={{ inputProps: { min: 1, max: 75 } }}
                        size="small"
                        value={copyQt[rowIndex] || 1}
                        onChange={(e) => {
                          let tmp = [...copyQt];
                          tmp[rowIndex] = Number(e.target.value);
                          setCopyQt(tmp);
                        }}
                      ></TextField>
                      <Tooltip title="Copy as New Row" placement="top-start">
                        <ContentCopyIcon
                          id="editsrf-ducInwardDetails-copybtn"
                          style={{ color: "#1976d2" }}
                          onClick={(e) => {
                            copyDescriptionRow(rowIndex);
                          }}
                        />
                      </Tooltip>
                      {/* {isMobile && ( */}
                      <Tooltip title="Open In Modal" placement="top-start">
                        <PreviewIcon
                          style={{ color: "#1976d2" }}
                          onClick={(e) => {
                            setOpenSRFModal(true);
                            setSelectedRowIndex(rowIndex);
                            setSelectedRow(row);
                          }}
                        />
                      </Tooltip>
                      {/* )} */}

                      {selectedRow && (
                        <SrfRowDetailsModal
                          open={openSRFModal}
                          handleClose={() => setOpenSRFModal(false)}
                          rowIndex={selectedRowIndex}
                          row={selectedRow}
                          fixedColumns={fixedColumns}
                          updateCellValue={updateCellValue}
                          setMultiValueData={setMultiValueData}
                          setMultiValueModalOpen={setMultiValueModalOpen}
                          instrumentList={instrumentList}
                          getInstrument={getInstrument}
                          set_DUIC_ID={set_DUIC_ID}
                          updateSrfDetails={updateSrfDetails}
                          setUnitFilter={setUnitFilter}
                          excelImport={excelImport}
                          settings={settings}
                          DUC_ID={DUC_ID}
                          checkDucMasterRecord={checkDucMasterRecord}
                          checkLatestInstrumentRecord={
                            checkLatestInstrumentRecord
                          }
                          additionalReadingColumns={additionalReadingColumns}
                          renderSRFRow={renderSRFRow}
                        />
                      )}

                      {showComponent("delete_in") && (
                        <Tooltip title="Delete Row" placement="top-start">
                          <DeleteIcon
                            id="editsrf-ducInwardDetails-deltebtn"
                            style={{ color: "#dc3545" }}
                            onClick={(e) => {
                              deleteDescriptionRow(rowIndex);
                              alert(
                                "DUC Instrument is deleted, click ok save to delete it permanently."
                              );
                            }}
                          />
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                  {renderSRFRow(
                    rowIndex,
                    row,
                    fixedColumns,
                    updateCellValue,
                    setMultiValueData,
                    setMultiValueModalOpen,
                    instrumentList,
                    getInstrument,
                    set_DUIC_ID,
                    updateSrfDetails,
                    setUnitFilter,
                    excelImport,
                    settings,
                    DUC_ID,
                    checkDucMasterRecord,
                    checkLatestInstrumentRecord
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      {
        <RenderModal
          modalOpen={modalOpen}
          handleClose={handleClose}
          readingRows={readingRows}
          currentRowIndex={currentRowIndex}
          setReadingRows={setReadingRows}
          tempReading={tempReading}
          DUC_ID={DUC_ID}
          srfDetails={srfDetails}
        />
      }
      {
        <RenderMultivalueModal
          multiValueData={multiValueData}
          multiValueModalOpen={multiValueModalOpen}
          setMultiValueModalOpen={setMultiValueModalOpen}
          readingRows={readingRows}
          updateCellValue={updateCellValue}
          unitFilter={unitFilter}
          instrumentArray={instrumentArray}
        />
      }
    </div>
  );
};
